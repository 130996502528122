@use 'global' as *;
// --------------------------------------------------------- / Component container

.card_container {
  align-items: stretch;
  background-color: var(--color-white);
  border-radius: var(--global-border-radius);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  color: var(--color-off-black);
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  max-width: 280px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  width: 100%;

  img {
    border-radius: var(--global-border-radius) var(--global-border-radius) 0 0;
    height: auto;
  }

  .button_container {
    padding: 0 1rem 1.5rem;
  }

  .arrow_dark {
    @include arrow;

    opacity: 0%;
    transition: opacity 0.3s ease;
  }

  &:hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    cursor: pointer;
    transform: scale(1.01) translateY(-8px);

    .arrow_dark {
      opacity: 100%;
    }
  }
}

.card_content {
  height: 100%;
  padding: 1rem;

  h3 {
    margin-bottom: 8px;
  }

  h3,
  p {
    &:hover {
      cursor: pointer;
    }
  }
}
